import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

import { ActionSheet, Button, Cell, CellGroup, Col, ConfigProvider, Dialog, Empty, Field, Icon, ImagePreview, Loading, NavBar, Notify, Overlay, Row, Search, ShareSheet, Stepper, Switch, Tab, Tabs, Tag } from 'vant'

import 'vant/lib/index.css';
import axios from 'axios'
import config from './store/config.js';
axios.defaults.baseURL = config.host;
axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded';



const app = createApp(App);
app.use(store).use(router);

app.use(ConfigProvider);
app.use(Button);
app.use(Tab);
app.use(Empty);
app.use(Tabs);
app.use(Icon);
app.use(Cell)
app.use(Field);
app.use(CellGroup);
app.use(Stepper);
app.use(ActionSheet);
app.use(Notify);
app.use(NavBar);
app.use(Tag);
app.use(Search);
app.use(ShareSheet);
app.use(Overlay);
app.use(Col);
app.use(Row);
app.use(ImagePreview);
app.use(Loading);
app.use(Dialog);
app.use(Switch);

app.mount('#app');

