import { createStore } from 'vuex'

import axios from 'axios'
export default createStore({
  state: {
    maizhen_data: [],   // 手腕数据
    taocan_data: [],    // 套餐
    taocan_order: [],   // 订单数据
    user_detail: {},    // 用户信息
    yxsc_data: [],       // 优选商城列表
    my_yxsc_order: [],    // 我的优选商城订单
    mendian_record: {},   // 区域门店
    jfxq_record: [],       // 积分详情
    jfxq_store:null,
  },
  mutations: {
    setBaseValue(state, payload) {
      state[payload[0]] = payload[1];
    }
  },
  actions: {
    async get_jfxq_record({ commit, state }, params) {
      let { data } = await axios.get('/api/api/get_jfxq_record', {
        params
      });
      commit('setBaseValue', ['jfxq_record', data.data]);
      commit('setBaseValue', ['jfxq_store', data.store_data] )
    },
    async zzjf_zhuanzhang({ commit, state }, params) {
      return await axios.get('/api/api/zzjf_zhuanzhang', {
        params
      });
    },
    async get_mendian_record({ commit, state }, params) {
      let { data } = await axios.get('/api/api/get_mendian_record', {
        params
      });
      commit('setBaseValue', ['mendian_record', data.data])
    },
    async yxsc_order({ commit, state }, params) {
      return await axios.get('/api/api/yxsc_order', {
        params
      });
    },
    async tihuo({ commit, state }, params) {
      return await axios.get('/api/api/tihuo', {
        params
      });
    },

    async get_yxsc_order({ commit, state }, params) {
      let { data } = await axios.get('/api/api/get_yxsc_order', {
        params
      });
      commit('setBaseValue', ['my_yxsc_order', data.data])
    },
    async get_yxsc_data({ commit, state }, params) {
      let { data } = await axios.get('/api/api/get_yxsc_data', {
        params
      });
      commit('setBaseValue', ['yxsc_data', data.data])
    },
    async get_user_detail({ commit, state }, params) {
      let { data } = await axios.get('/api/api/get_user_detail', {
        params
      });
      commit('setBaseValue', ['user_detail', data.data])
    },
    async submit_maizhen_data({ commit, state }, params) {
      return await axios.get('/api/api/submit_maizhen_data', {
        params
      });
    },
    async get_maizhen_data({ commit, state }, params) {
      let { data } = await axios.get('/api/api/get_maizhen_data', {
        params
      });
      commit('setBaseValue', ['maizhen_data', data.data])
    },
    async get_taocan_data({ commit, state }, params) {
      let { data } = await axios.get('/api/api/get_taocan_data', {
        params
      });
      commit('setBaseValue', ['taocan_data', data.data])
    },
    async taocan_order({ commit, state }, params) {
      return await axios.get('/api/api/taocan_order', {
        params
      });
    },
    async get_taocan_order({ commit, state }, params) {
      let { data } = await axios.get('/api/api/get_taocan_order', {
        params
      });
      commit('setBaseValue', ['taocan_order', data.data])
    },
    async taocan_xiaofei({ commit, state }, params) {
      return await axios.get('/api/api/taocan_xiaofei', {
        params
      });
    },
  },
  modules: {
  }
})
