<template>
  <van-config-provider :theme-vars="themeVars">
    <div v-if="$route.query.sjhm">
      <router-view />
    </div>
    <VanEmpty v-else>没有手机号码</VanEmpty>
  </van-config-provider>
</template>
<script>
import { showNotify } from 'vant';

export default {
  data() {
    return {
      themeVars: {
        buttonPrimaryBackground: '#3eb6c6',
        buttonPrimaryBorderColor: '#3eb6c6',
        tabsBottomBarColor: '#3395a2',
        fieldLabelColor: '#3fb6c6',
        fieldLabelWidth: '4em',
      },
    }
  },
  created() {

  },
  async mounted() {
    await this.$router.isReady();
    console.log(this.$route.query);
    if (!this.$route.query?.sjhm) {
      showNotify({ type: 'danger', message: '参数不完整' })
    }
  }
}
</script>
<style lang="less">
html,
body {
  margin: 0;
  padding: 0;
  background: #f4f4f4;
}

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 16px;
}

:root:root {
  --van-border-color: #dedede;
  --van-font-size-md: 16px;
}


.van-cell-group {
  .van-cell {
    font-size: 16px;
  }
}

.common-tabs {
  .van-tabs__wrap {
    padding: 0 30px;
    padding-top: 30px;
    background: #3fb6c6;
    // border-bottom: 6px solid #fff;

    .van-tabs__nav {
      border-top-left-radius: 5px;
      border-top-right-radius: 5px;
    }
  }
  .van-tabs__line {
    bottom:20px;
  }
}
</style>
