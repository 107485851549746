<template>
  <div class="home">
    <van-tabs v-model:active="active" class="common-tabs">
      <van-tab title="手腕数据">
        <div class="table">
          <div class="thead">
            <img src="@/assets/img/jksjhead.jpg" alt="" class="thead-img">
          </div>
          <div class="tbody" v-if="maizhen_data">
            <div class="tr" v-for="item in maizhen_data">
              <div class="td">
                {{ getShortTime(item.input_time, 5, 10) }}
              </div>
              <div class="td" :style="{color:item.sw_zuo_maobo_color}">
                {{ item.sw_zuo_maibo || '-' }}
              </div>
              <div class="td" :style="{color:item.sw_zuo_diya_color}">
                {{ item.sw_zuo_diya || '-' }}
              </div>
              <div class="td" :style="{color:item.sw_zuo_gaoya_color}">
                {{ item.sw_zuo_gaoya || '-' }}
              </div>
              <div class="td" :style="{color:item.sw_you_maibo_color}">
                {{ item.sw_you_maibo || '-' }}
              </div>
              <div class="td" :style="{color:item.sw_you_diya_color}">
                {{ item.sw_you_diya || '-' }}
              </div>
              <div class="td" :style="{color:item.sw_you_gaoya_color}">
                {{ item.sw_you_gaoya || '-' }}
              </div>
            </div>
          </div>
        </div>
      </van-tab>
      <van-tab title="上臂数据">
        <div class="table">
          <div class="thead">
            <img src="@/assets/img/jksjhead.jpg" alt="" class="thead-img">
          </div>
          <div class="tbody" v-if="maizhen_data">
            <div class="tr" v-for="item in maizhen_data">
              <div class="td">
                {{ getShortTime(item.input_time, 5, 10) }}
              </div>
              <div class="td" :style="{color:item.sb_zuo_maibo_color}">
                {{ item.sb_zuo_maibo || '-' }}
              </div>
              <div class="td" :style="{color:item.sb_zuo_diya_color}">
                {{ item.sb_zuo_diya || '-' }}
              </div>
              <div class="td" :style="{color:item.sb_zuo_gaoya_color}">
                {{ item.sb_zuo_gaoya || '-' }}
              </div>
              <div class="td" :style="{color:item.sb_you_maibo_color}">
                {{ item.sb_you_maibo || '-' }}
              </div>
              <div class="td" :style="{color:item.sb_you_diya_color}">
                {{ item.sb_you_diya || '-' }}
              </div>
              <div class="td" :style="{color:item.sb_you_gaoya_color}">
                {{ item.sb_you_gaoya || '-' }}
              </div>
            </div>
          </div>
        </div>
      </van-tab>
      <van-tab title="图文数据">
        <VanEmpty>暂未开放</VanEmpty>
      </van-tab>
    </van-tabs>
  </div>
</template>

<script>
// @ is an alias to /src

import { mapActions, mapState } from 'vuex';

export default {
  name: 'Home',
  data() {
    return {
      active: 0
    }
  },
  created() {
    // console.log(this.$route);
    this.get_maizhen_data({ sjhm: this.$route.query.sjhm });
  },
  computed: {
    ...mapState(['maizhen_data']),
  },
  methods: {
    ...mapActions(['get_maizhen_data']),
    getShortTime(time, start, end) {
      if (end) {
        return time.substring(start, end);
      }
      else return time.substring(start);
    }
  },

}
</script>
<style lang="less" scoped>
.table {
  text-align: center;

  .thead {
    color: #fff;
    font-size: 14px;

    .thead-img {
      width: 100%;
      height: auto;
    }

    .tr {
      display: flex;
      height: 32px;
      align-items: center;
      justify-content: space-between;

      background: #3395a2;

      &:nth-child(2n) {
        background: #58ded7;
      }

      .td {
        flex: 1;
      }
    }
  }

  .tbody {
    color: #333;
    font-size: 16px;

    .tr {
      display: flex;
      height: 44px;
      align-items: center;
      justify-content: space-between;

      .td {
        flex: 1;

        &.red {
          color: red;
        }
      }
    }
  }
}
</style>
