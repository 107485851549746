import { createRouter, createWebHashHistory } from 'vue-router'
import Home from '../views/Home.vue'

const routes = [
  {
    path: '/',
    redirect: '/jksj',
  },
  {
    path: '/jksj',
    name: 'Jksj',
    component: Home,
    meta: {
      title: '健康数据'
    }
  },
  {
    path: '/mzlr',
    name: 'Mzlr',
    component: () => import('../views/mzlr.vue'),
    meta: {
      title: '脉诊录入'
    }
  },
  {
    path: '/fwtc',
    name: 'Fwtc',
    component: () => import('../views/Fwtc'),
    meta: {
      title: '服务套餐'
    }
  },
  {
    path: '/fwxf',
    name: 'Fwxf',
    component: () => import('../views/Fwxf'),
    meta: {
      title: '服务消费'
    }
  },
  {
    path: '/fwxd',
    name: 'Fwxd',
    component: () => import('../views/Fwxd'),
    meta: {
      title: '服务下单'
    }
  },
  {
    path: '/spxd',
    name: 'Spxd',
    component: () => import('../views/Spxd'),
    meta: {
      title: '商品下单'
    }
  },
  {
    path: '/zzzz',
    name: 'Zzzz',
    component: () => import('../views/Zzzz'),
    meta: {
      title: '种子转账'
    }
  },
  {
    path: '/yxsc',
    name: 'Yxsc',
    component: () => import('../views/Yxsc'),
    meta: {
      title: '优选商城'
    }
  },
  {
    path: '/yxsc-detail',
    name: 'YxscDetail',
    component: () => import('../views/Yxsc/detail'),
    meta: {
      title: '商品详情'
    }
  },
  {
    path: '/ddqr',
    name: 'Ddqr',
    component: () => import('../views/Ddqr'),
    meta: {
      title: '订单确认'
    }
  },
  {
    path: '/ddqr_normal',
    name: 'DdqrNormal',
    component: () => import('../views/Ddqr/normal.vue'),
    meta: {
      title: '订单确认'
    }
  },
  {
    path: '/jfxq',
    name: 'Jfxq',
    component: () => import('../views/Jfxq'),
    meta: {
      title: '积分详情'
    }
  },
  {
    path: '/mdgl',
    name: 'Mdgl',
    component: () => import('../views/Mdgl/index.vue'),
    children:[
      {
        path:'/mdgl/user_zhibiao',
        component:()=>import('../views/Mdgl/UserZhiBiao.vue'),
        meta: {
          title: '会员指标'
        }
      },
      {
        path:'/mdgl/tihuo',
        component:()=>import('../views/Mdgl/PickUpManage.vue'),
        meta: {
          title: '提货管理'
        }
      },
      {
        path:'/mdgl/xiaoshou',
        component:()=>import('../views/Mdgl/SellManage.vue'),
        meta: {
          title: '销售管理'
        }
      },
      {
        path:'/mdgl/jifen',
        component:()=>import('../views/Mdgl/jifen.vue'),
        meta: {
          title: '积分管理'
        }
      },
      {
        path:'/mdgl/taocan',
        component:()=>import('../views/Mdgl/taocan.vue'),
        meta: {
          title: '套餐管理'
        }
      },
      {
        path:'/mdgl/shangpin',
        component:()=>import('../views/Mdgl/shangpin.vue'),
        meta: {
          title: '商品管理'
        }
      },
      {
        path: '/mdgl',
        redirect: '/mdgl/user_zhibiao',
      },
    ],
    meta: {
      title: '门店管理'
    }
  },
  {
    path: '/smsj',
    name: 'Smsj',
    component: () => import('../views/Smsj/smsj.vue'),
    meta: {
      title: '睡眠数据'
    }
  },
  {
    path: '/yxdz',
    name: 'Yxdz',
    component: () => import('../views/Xydz/xydz.vue'),
    meta: {
      title: '隐私协议'
    }
  },
  {
    path: '/about',
    name: 'About',
    component: () => import(/* webpackChunkName: "about" */ '../views/About.vue'),
    meta: {
      title: '关于我们'
    }
  },
  {
    path: '/jkbg',
    name: 'Jkbg',
    component: () => import('../views/jkbg/index.vue'),
    meta: {
      title: '健康报告'
    }
  },
  {
    path: '/sjsz',
    name: 'Sjsz',
    component: () => import('../views/sjsz/index.vue'),
    meta: {
      title: '手机设置'
    }
  },
  {
    path: '/yqyh',
    name: 'Yqyh',
    component: () => import('../views/yqyh/index.vue'),
    meta: {
      title: '邀请用户'
    }
  },
]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})
router.beforeEach(async (to) => {
  if (to.meta.title) { // 判断是否有标题
    document.title = to.meta.title;
  }
});

export default router
